import { useEffect, useState } from "react";
const TypingEffect = ({ text, speed, chatContainerRef ,stopLoader }) => {
    const [displayText, setDisplayText] = useState('');

    useEffect(() => {
        let currentIndex = 0;

        const typingInterval = setInterval(() => {
            if (currentIndex <= text.length) {
                setDisplayText(text.slice(0, currentIndex));
                currentIndex += 1;
            } else {
                clearInterval(typingInterval);
                stopLoader(false);
            }
            if (chatContainerRef.current) {
                chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
            }
        }, speed);

        return () => {
            clearInterval(typingInterval);
        };
    }, [text, speed]);

    return <span>{displayText}</span>;
}
export default TypingEffect;