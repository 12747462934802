import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import UserChatList from "./userChatList";
const send = "/img/arrow.svg";
const ellipse = "/img/Ellipse24.png";
const Instragram = "/img/instagram.svg";
const Discord = "/img/Discord.svg";
const Twitter = "/img/twitter.svg";

const Email = ({ data, questionData, loader }) => {
    const [enableButton, setEnableButton] = useState(false);
    const instaLink = "https://www.instagram.com/zofit.ai/";
    const twitterLink = "https://x.com/zofit_ai?s=11&t=kxaK18drqeZHwD86k7vOwA";
    const discordLink = "https://discord.com/invite/BJ9tbtw9";
    const toggleClass = () => {
        const element = document.getElementById("mobile-element"); // Get the element by its ID
        if (element) {
            element.classList.toggle("expand-div"); // Toggle the 'active' class
        }
    };
    // useEffect(() => {
    //     // if (loader === true) {
    //     const element = document.getElementById("mobile-element"); // Get the element by its ID
    //     if (element) {
    //         element.classList.add("expand-div"); // Toggle the 'active' class
    //     }
    //     // }
    // }, [data]);

    const openChatInMobile = () => {
        const element = document.getElementById("mobile-element"); // Get the element by its ID
        if (element) {
            element.classList.add("expand-div"); // Toggle the 'active' class
        }
    }
    const [inputData, setInputData] = useState("");
    const handleClick = (e) => {
        e.preventDefault();
        questionData(inputData);
        setEnableButton(true);
        setInputData('');
    }
    const disableIcon = (data) => {
        setEnableButton(false);
    }
    return (
        <div id="mobile-element">
            <div className="profile-images-mobile" onClick={toggleClass}>
                <img src={ellipse} alt="mobile image" />
            </div>

            <div className="waitList socails-padding">
                <div className="mobile-block">
                    <UserChatList data={data} searchQuery={''} questionData={questionData} loader={loader} stopLoader={disableIcon} />
                </div>
                <div className="none-forms">
                    {/* <input
                        className="form-control color-place"
                        type="search"
                        placeholder="Enter Email"
                    />
                    <a href="#" className="submit-btn">
                        Join the waitlist
                    </a> */}
                    <div className="bottom-soacials">
                        <div>
                            <Link to={instaLink} target="_blank"><img src={Instragram} className="img-fluid" alt="icon" /></Link>
                            <p>@Zofit</p>
                        </div>
                        <div>
                            <Link to={discordLink} target="_blank"><img src={Discord} className="img-fluid" alt="icon" /></Link>
                            <p>@Zofit</p>
                        </div>
                        <div>
                            <Link to={twitterLink} target="_blank"> <img src={Twitter} className="img-fluid" alt="icon" /></Link>
                            <p>@Zofit</p>
                        </div>
                    </div>
                </div>

                <div className="footer-chat none-other">
                    <div className="profile-mobile-chat">
                        <div className="profile-images" onClick={toggleClass}>
                            <img src={ellipse} alt="mobile image" />
                        </div>
                        <form method="post" onSubmit={handleClick}>
                            <input
                                type="text"
                                className="write-message"
                                onChange={(e) => {setInputData(e.target.value); openChatInMobile();}}
                                value={inputData}
                                placeholder="Talk to Zo"
                            />
                            {enableButton === true ?
                                <div className="spinner-border send-plane" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                                :
                                <button type="submit" className="submitButton">
                                    <img src={send} className={'send-plane'} alt="icon" />
                                </button>

                            }
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Email;
