import { useState } from "react";
import Sidebar from "./sidebar";
import apiController from '../../controllers/apiController';
import Loader from '../common/Loader/Loader';
import "../../assets/css/style.css";
const ellipse = "/img/Ellipse24.png"
const search = "/img/search.svg"
const send = "/img/Send.svg"
const ChatStart = () => {
    const [chatText, setChatText] = useState('');
    const [chatSessionText, setChatSessionText] = useState('');
    const [loading, setLoading] = useState(false);
    const setChatValue = (e) => {
        const queryValue = e.target.value;
        setChatText(queryValue);
    }
    const submitChat = (e) => {
        e.preventDefault();
        setLoading(true)
        submitData();
    }
    const submitData = async () => {
        try {
            const uuid = localStorage.getItem('uuid');
            const endPoint = process.env.REACT_APP_API_CHAT;
            const payload = {
                message: chatText,
                uuid: uuid
            };
            const result = await apiController.postData(endPoint, payload);
           
            setChatSessionText(result);
            
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };
    return (
        <div className="right-section">
            <div className="profile-picture">
                <img src={ellipse} className="img-fluid" alt="ellipse" />
            </div>
            <h2>Hi, I’m Zo! <br />How can I help you today?</h2>
            <div className="position-relative">
                {/* <img src={search} className="position-absolute" alt="search" />
                <input className="form-control ava-input" type="search" placeholder="Search..." /> */}
            </div>
        </div>
    )
}
export default ChatStart;