import { useEffect,useRef } from "react";
import { Link } from "react-router-dom";
import Email from "./email";
const logo = "/img/white-logo-glow.svg";
const smartMockups = "/img/smartMockupsIp2coz9u11.png"
const dynamic = "/img/Group48095791.png"
const frame = "/img/footer-img.png"
const Instragram = "/img/instagram.svg";
const Discord = "/img/Discord.svg";
const Twitter = "/img/twitter.svg";

const Sidebar = ({ data, questionData, loader }) => {
    const elRef = useRef(null);
    let timer = null;
    const instaLink = "https://www.instagram.com/zofit.ai/";
    const twitterLink = "https://x.com/zofit_ai?s=11&t=kxaK18drqeZHwD86k7vOwA";
    const discordLink = "https://discord.com/invite/BJ9tbtw9";


    useEffect(() => {
        const el = elRef.current;
        
        if (el) {
            el.classList.add('scroll-11');
        }
    }, []);
    return (
        <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 px-0 min-width-mobile">
            <div className="left-profilePart">
                <div className="cover-bgs" >
                    <div className="avaFit-left">
                        <div className="d-flex-waitlist">
                            <img src={logo} alt="logo"  className="logo-size"/>
                            {/* <span className="mobile-join-Waitlist">Join Waitlist</span> */}
                        </div>

                        <h1>Meet Zo</h1>
                        <h2>Your ultimate AI-powered<br /> personal trainer</h2>
                        <p>We understand that achieving your fitness goals can be a challenging journey, and that's why we've brought cutting-edge technology to your fingertips. </p>
                        <p>Our AI personal trainer is designed to revolutionize your fitness experience, providing personalized guidance, motivation, and support tailored just for you.</p>
                        <img src={smartMockups} className="img-fluid" alt="smartMockups" />
                    </div>
                    <div className="dynamic-workouts">
                        <h2>Dynamic workouts</h2>
                        <h3>Designed uniquely for your body</h3>
                        <p>Experience a new level of personalized fitness. Our dynamic workout plans adapt to your individual needs, ensuring you get the most effective and enjoyable sessions. Whether you aim to build strength, increase flexibility, or improve endurance, our tailored programs are here to support your journey.</p>
                        <p>Adjust the intensity of each exercise to match your progress and push your limits safely. Start your workout today and discover the difference a customized plan can make.</p>
                        <img src={dynamic} className="img-fluid" alt="dynamic" />
                    </div>

                    <div className="not-just-an-ai 13">
                        <div className="ai-padding">
                            <h2>Not just an AI</h2>
                            <h3>A mentor. A coach. A friend.</h3>
                            <p>Our AI-driven platform is more than just a tool—it's your personal mentor, dedicated coach, and supportive friend on your fitness journey. With advanced algorithms and personalized insights, we help you achieve your goals faster and more efficiently.</p>
                            <p>Experience the power of smart technology that understands your unique needs and adapts to provide the best guidance, motivation, and support. Let's redefine what fitness can be together.</p>
                        </div>
                        <img src={frame} className="img-fluid w-100" alt="frame" />
                    </div>
                    <div className="none-form mobile">
                        {/* <input
                            className="form-control color-place"
                            type="search"
                            placeholder="Enter Email"
                        />
                        <a href="#" className="submit-btn">
                            Join the waitlist
                        </a> */}
                        <div className="bottom-soacials">
                            <div>
                                <Link to={instaLink} target="_blank"><img src={Instragram} className="img-fluid" alt="icon" /></Link>
                                <p>@Zofit</p>
                            </div>
                            <div>
                                <Link to={discordLink} target="_blank"><img src={Discord} className="img-fluid" alt="icon" /></Link>
                                <p>@Zofit</p>
                            </div>
                            <div>
                                <Link to={twitterLink} target="_blank"> <img src={Twitter} className="img-fluid" alt="icon" /></Link>
                                <p>@Zofit</p>
                            </div>
                        </div>
                    </div>
                </div>
                <Email data={data} questionData={questionData} loader={loader} />
            </div>
        </div>
    );
}
export default Sidebar;