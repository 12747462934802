// App.js or your main component
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Chat from './components/chat';
import "./assets/css/bootstrap.min.css";
import "./assets/css/responsive.css"; 
function App() {
  let path = (window.location.pathname).split('/')
  let uuid = localStorage.getItem('uuid');
  if(!uuid){
    return <Home /> ;
  }
  else if(uuid && path[1]==''){
    window.location.href='/chat';
  }
  return (
    <>
      {path[1] === 'chat' ?
        <Router>
          <Routes>
            <Route path="/chat" element={<Chat />} />
         
          </Routes>
        </Router> :
        <Router>

          <Routes>
            <Route path="/" element={<Home />} />
           
            {/* Add more routes as needed */}
          </Routes>


        </Router>
      }
    </>
  );
}

export default App;
