import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import Emailform from "./emailform";
import "./background.css";
import '../assets/css/style.css';
import "./home.css";

const qnlist = '/img/questionList.png';
const Trending = './img/trending-up.svg';

const Home = () => {

  const options = {
    items:1,
    loop:true,
    margin:10,
    autoplay:true,
    autoplayTimeout:3000,
    autoplayHoverPause:true,
    animateOut: 'fadeOutUp',
    animateIn: 'fadeInUp',
    dots: false,
    responsiveClass: true,
  };

  return (

    <div className="ava-meet-body">
      <div className="text-center w-100">
        <img src="img/white-logo-glow.svg" className="logo-top-side" alt="logo" />
        <h1>Meet Zo</h1>
        <p className="ai-personal-trainer">The AI-Personal Trainer</p>
        <p className="short-peragraph">
          Zo can answer your questions about Workout Routines, Progress
          Analysis, Goal Mapping and Holistic health
        </p>
        
        <div className="wrapper-slider">
        <OwlCarousel className='owl-theme' {...options}>
          <div className="item">
            <div className="d-flex">
              <img src={Trending} className="most-asked" alt='icon' />
              <span className="most-asked-color">Most Asked</span>
            </div>
            <p>How can i lose weight in 4 weeks?</p>
          </div>
          <div className="item">
            <div className="d-flex">
              <img src={Trending} className="most-asked" alt='icon' />
              <span className="most-asked-color">Most Asked</span>
            </div>
            <p>Best exercise for abs strength?</p>
          </div>
          <div className="item">
            <div className="d-flex">
              <img src={Trending} className="most-asked" alt='icon' />
              <span className="most-asked-color">Most Asked</span>
            </div>
            <p>How to gain muscle mass?</p>
          </div>	
          <div className="item">
            <div className="d-flex">
              <img src={Trending} className="most-asked" alt='icon' />
              <span className="most-asked-color">Most Asked</span>
            </div>
            <p>How to improve workout intensity?</p>
          </div>
          <div className="item">
            <div className="d-flex">
              <img src={Trending} className="most-asked" alt='icon' />
              <span className="most-asked-color">Most Asked</span>
            </div>
            <p>How to increase running stamina?</p>
          </div>
         </OwlCarousel>
      </div>
      <Emailform />
    </div>
    </div >
  );
}

export default Home;
