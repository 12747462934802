import { useEffect, useRef, useState } from "react";
import apiController from "controllers/apiController";
import './chat.css';
import TypingEffect from "./TypingEffect";

const UserChatList = ({ data, searchQuery, questionData, loader ,stopLoader}) => {
    const chatContainerRef = useRef(null);
    const [searchQ, setSearchQ] = useState();
    const maxWords = 60;
    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
        
    }, [data]);

    useEffect(() => {
        preDefinedQue();
        setSearchQ(searchQuery);

    }, [searchQuery]);

    useEffect(()=>{
        if (searchQ) {
            // Your logic for handling the updated searchQ
            console.log('SearchQ updated:', searchQ);
        }
        searchAndHighlight(); // Call searchAndHighlight whenever searchQuery changes
    }, [searchQ])
    const totalMessage = data.length;
    const [expandedMessages, setExpandedMessages] = useState([]);
    const truncateText = (text, limit) => {
        const words = text.split(' ');
        const truncatedWords = words.slice(0, limit);
        return truncatedWords.join(' ');
    };

    const handleReadMoreClick = (index) => {
        setExpandedMessages((prevExpanded) => {
            const newExpanded = [...prevExpanded];
            newExpanded[index] = !newExpanded[index];
            return newExpanded;
        });
    };

    const [preQuestion, setPreQuestion] = useState([]);
    const preDefinedQue = async () => {
        try {
            const endPoint = process.env.REACT_APP_API_GET_QUE;
            const result = await apiController.getData(endPoint);
            setPreQuestion(result.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const searchAndHighlight = () => {
        resetHighlights(); // Clear existing highlights
    
        if (!searchQ) {
            return; // No search query, nothing to highlight
        }
        const sanitizedSearchQ = (searchQ as string).replace(/\s+/g, ' ');

        if (!sanitizedSearchQ) {
            return; 
        }
    
        const regex = new RegExp(`(${escapeRegExp(sanitizedSearchQ)})`, 'gi');

        const elements = document.querySelectorAll('.reply-message, .message-send');
    
        elements.forEach(element => {
            if (element.nodeType === 3) {
                // Text node
                const originalText = element.nodeValue;
                const newText = originalText.replace(regex, match => `<span class="fw-bold">${match}</span>`);
    
                if (originalText !== newText) {
                    const newElement = document.createElement('span');
                    newElement.innerHTML = newText;
                    element.replaceWith(newElement);
                }
            } else if (element.nodeType === 1) {
                // Element node
                const originalHtml = element.innerHTML;
                const newHtml = originalHtml.replace(regex, match => `<span class="fw-bold">${match}</span>`);
    
                if (originalHtml !== newHtml) {
                    element.innerHTML = newHtml;
                }
            }
        });
    };
    
    
    const resetHighlights = () => {
        const highlightedElements = document.querySelectorAll('.fw-bold');
    
        highlightedElements.forEach(element => {
            const parent = element.parentNode;
            const textNode = document.createTextNode(element.textContent);
            parent.replaceChild(textNode, element);
        });
    };
    
    // Escape special characters in the searchQuery to prevent them from affecting the regex
    const escapeRegExp = (string) => {
        return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    };
   
    return (
        <>

            {(totalMessage > 0) ?
                <div className="max-chats-options " id="scrollId">
                    <div className="chat-message scroll-data-history " ref={chatContainerRef}>
                        {
                            data.map((rec: any, index: any) => {
                                const cssClass: any = rec.messageType === "user" ? "reply-message" : "message-send";

                                return (
                                    <div className={cssClass}>
                                        <p>
                                            {rec?.typing === true ? 
                                               <TypingEffect text={rec.messageText} speed={10} chatContainerRef= {chatContainerRef}  stopLoader={stopLoader}/>
                                            :
                                             expandedMessages[index] || rec.messageText.split(' ').length <= maxWords ? rec.messageText : `${truncateText(rec.messageText, maxWords)} ...`}
                                            {rec.messageText.split(' ').length > maxWords && ( <span onClick={() => handleReadMoreClick(index)}> {expandedMessages[index] ? 'Read Less' : 'Read More'} </span> )}
                                        </p>
                                    </div>
                                )

                            })
                        }
                        {loader ?
                            <div className={"message-send"}>
                                <div className="chat-loader"></div>
                            </div>
                            :
                           null}
                        
                    </div>

                </div>
                :
                <div className="max-chats-options">
                    <div className="row">
                        {
                            preQuestion.map((value: any) => {
                                return (
                                    <>
                                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 d-flex">
                                            <button className="workout-chats" onClick={(e) => questionData(value.title + ' ' + value.heading)}>
                                                <p>{value.title}</p>
                                                <h3>{value.heading}</h3>
                                            </button>
                                        </div>
                                    </>
                                );
                            })
                        }

                    </div>
                </div>
            }
        </>
    );
}
export default UserChatList;