import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL; // replace with your API base URL
const getUUID = localStorage.getItem('uuid');
const apiController = {
  getData: async (endPoint: string) => {
    try {
      const headers  = {
        'Content-Type': 'application/json',
        'x-authorization-uuid': getUUID 
      };
      const response = await axios.get(`${apiUrl}/${endPoint}`,{
        headers,
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  },
  postData: async (endPoint: string, payload: any) => {
    try {
      
      const headers  = {
        'Content-Type': 'application/json',
        'x-authorization-uuid': getUUID 
      };
      const response = await axios.post(`${apiUrl}/${endPoint}`, payload, {
        headers,
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error.response.data);
      // return error.response.data;
      throw error;
    }
  }
};

export default apiController;
