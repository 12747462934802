import { useState } from "react";
import apiController from "controllers/apiController";
import Loader from "./common/Loader/Loader";
const Emailform = () => {
    const [email, setEmail] = useState('');
    const [chatSessionText, setChatSessionText] = useState('');
    const [loading, setLoading] = useState(false);
    const setTextValue = (e) => {
        const queryValue = e.target.value;
        setEmail(queryValue);
       
    }
    const submitRegistration = (e) => {
        e.preventDefault();
        setLoading(true)
        submitData();
    }
    const submitData = async () => {
        try {
            const endPoint = process.env.REACT_APP_API_LOGIN;
            const payload = {
                email: email
            };
            const result = await apiController.postData(endPoint, payload);
          
            setChatSessionText(result);
            if (result.code === 200) {
                localStorage.setItem('uuid', result.data.uuid);
                setLoading(false)
                window.location.href = 'chat';

            }

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };
    return (

        <form action="">
            {loading === true ? <Loader /> : null}
            {/* <Loader /> */}
            <div className="input-group form-control-width">

                <input type="text" className="form-control text-white" onChange={(e) => setTextValue(e)} placeholder="Your Email *" />
                <div className="input-group-append">
                    <button type="submit" className="input-group-text" onClick={(e) => submitRegistration(e)}>
                        <span >Talk to Zo</span>
                    </button>
                </div>
            </div>
            <span className="short-text-info">*This is a wait list for our mobile app</span>
        </form>
    );
}
export default Emailform;