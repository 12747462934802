import { useEffect, useState, useRef } from "react";
import Sidebar from "./sidebar";
import apiController from '../../controllers/apiController';
import Loader from '../common/Loader/Loader';
import ChatStart from "./chatStart";
import UserChatList from "./userChatList";
import "../../assets/css/style.css?v=0";
const ellipse = "/img/Ellipse24.png"
const search = "/img/search.svg"
const send = "/img/arrow.svg"
const stop = "/img/stop.png"
const Chat = () => {
    const [chatText, setChatText] = useState('');
    const [chatTextMobile, setChatTextMobile] = useState('');
    const [chatSessionText, setChatSessionText] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [chatList, setChatList] = useState([]);
    const [enableButton, setEnableButton] = useState(false);
    const [loading, setLoading] = useState(false);
    const ref = useRef(null);
    const uuid = localStorage.getItem('uuid');
    const [chatBtn, setChatBtn] = useState('send-plane');
    useEffect(() => {
        chatHistory();
    }, []);

    useEffect(() => {

        if (chatText == "") return
        setChatList((prevChatList) => [
            ...prevChatList,
            { messageText: chatText, messageType: 'user', dateTime: new Date() },
        ]);
        submitData()
    }, [chatTextMobile])
    const setChatValue = (e) => {
        const queryValue = e.target.value;
        if (!queryValue) {
            setChatBtn('send-plane');
        }
        else {
            setChatBtn('send-plane-btn');
        }
        setChatText(queryValue);
    }
    const submitChat = (e) => {
        e.preventDefault();
        if (chatText != "") {
            setLoading(true)

            setChatList((prevChatList) => [
                ...prevChatList,
                { messageText: chatText, messageType: 'user', dateTime: new Date() },
            ]);
            submitData();


        }
    }
    const mobileSubmitChat = async (data: any) => {
        setChatText(data);
        setChatTextMobile(data);
    }
    const [maxLimit, setMaxLimit] = useState(false);
    const [limitMsg, setLimitMsg] = useState('');
    const submitData = async () => {
        try {
            setLoading(true);
            setEnableButton(true);
            setChatText('');
            const endPoint = process.env.REACT_APP_API_CHAT;
            const payload = {
                message: chatText,
                uuid: uuid
            };

            const result = await apiController.postData(endPoint, payload);
            setChatText('');
            if (result.code === 200) {

                setChatText('');
                // setChatSessionText(result);

                setChatList((prevChatList) => [
                    ...prevChatList,
                    { messageText: result.data.messageResponse, messageType: 'bot', dateTime: result.data.messageResponse, typing: true },
                ]);

            }
            else if (result.code === 400) {
                setChatText('');
                setLimitMsg(result.message);
                setMaxLimit(true);
                return false;
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setChatText('');
            setLimitMsg(error.response.data.message);
            setMaxLimit(true);
            return false;
        } finally {
            setLoading(false);
        }
    };
    const chatHistory = async () => {
        try {
            const endPoint = process.env.REACT_APP_API_CHAT_LIST;
            const payload = {
                "uuid": uuid,
                "pageNo": 1,
                "noOfRecords": 20
            };
            const result = await apiController.postData(endPoint, payload);
            if (!result.data) {
                setChatList([]);
            }
            else {
                setChatList(result.data);
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }
    const clearChat = async () => {
        try {
            setLoading(true);
            const endPoint = process.env.REACT_APP_API_CLEAR_CHAT;
            const result = await apiController.getData(endPoint);
            setChatList([]);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }
    const selectPreDefineQue = (data) => {
        setChatText(data);
        setChatTextMobile(data);
    }
    const searchTrigger = (e) => {
        e.preventDefault();
        let textValue = e.target.value;
       
        setSearchQuery(textValue);
    }
    const disableIcon = (data) => {
        setEnableButton(false);
    }
    return (
        <div className={"chat"}>
            <div className="container-fluid">
                <div className="row">
                    <Sidebar data={chatList} questionData={mobileSubmitChat} loader={loading} />

                    <div className="col-sm-9 col-md-9 col-lg-9 col-xl-9 px-0 bg-chat-color mobile-none">

                        <div className="full-chat-screen">
                            {chatList.length > 0 ?
                                <div className="full-color-white-bar">
                                    <div className="topbar">
                                        <div className="flex-inline ">
                                            <div className="top-profile-part">
                                                <img src={ellipse} className="img-fluid" alt="ellipse" />
                                            </div>
                                            <div>
                                                <p>You are talking to</p>
                                                <h4>Zo</h4>
                                            </div>
                                        </div>
                                        <div className="position-relative min-width-search m-0">
                                            <img src={search} className="position-absolute" alt="search" />
                                            <input className="form-control ava-input" type="search" onChange={(e) => searchTrigger(e)} placeholder="Search..." />
                                        </div>
                                        <a className="reset-btn" href="javascript:void(0)" onClick={clearChat}>Reset</a>
                                    </div>
                                </div>

                                : <ChatStart />}


                            <div className="fit-position-bottom">


                                <UserChatList data={chatList} searchQuery={searchQuery} questionData={selectPreDefineQue} loader={loading}  stopLoader = {disableIcon} />
                                {maxLimit === true ?

                                    <div className="alert alert-warning ml-3 mr-3" role="alert">{limitMsg}</div>
                                    :
                                    <div className="footer-chat">
                                        <form onSubmit={(e) => submitChat(e)}>
                                            <input
                                                type="text"
                                                className="write-message"
                                                onChange={(e) => setChatValue(e)}
                                                value={chatText}
                                                placeholder="Talk to Zo"
                                            />
                                            {enableButton === true ?
                                                <div className="spinner-border send-plane" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                                : 
                                                <button type="submit" className="submitButton">
                                                    <img src={send} className={'send-plane'} alt="icon" />
                                                </button>

                                            }


                                        </form>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Chat;
